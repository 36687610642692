import React, { Fragment } from "react"
import Breadcrumbs from "pages/Cashes/components/Breadcrumbs"
import { Card, Paginate } from "components/UI"
import useCashes from "hooks/useCashes"
import useSelectAll from "hooks/useSelectAll"
import useModalTrigger from "hooks/useModalTrigger"
import Table from "./components/Table"
import Filter from "./components/Filter"
import EditModal from "./components/EditModal"
import ActionsPanel from "./components/ActionsPanel"

const List = ({ cashOptions, ...props }) => {
  const { cashes, fetchCashes, updateCashesWithRefresh, destroyCashWithFetch } = useCashes(props.cashes)
  const [isEditModalOpen, editModalData, openEditModal, closeEditModal] = useModalTrigger()
  const [
    selectedCashes,
    totalSelectedCash,
    isCheckedCash,
    onCheckedCash,
    isCheckedAllCashes,
    onCheckedAllCashes,
    resetSelected
  ] = useSelectAll(cashes.pagination.totalCount)

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Filter
          options={cashOptions}
          collection={cashes}
          handleSubmit={async (values) => fetchCashes({ filter: values })}
        />
      </Card>
      {totalSelectedCash > 0 && <ActionsPanel cashes={cashes} selectedCashes={selectedCashes} resetSelected={resetSelected} />}
      <Table
        collection={cashes}
        destroyCashWithFetch={destroyCashWithFetch}
        isCheckedAllCashes={isCheckedAllCashes}
        isCheckedCash={isCheckedCash}
        onCheckedAllCashes={onCheckedAllCashes}
        onCheckedCash={onCheckedCash}
        openEditModal={openEditModal}
        totalSelectedCash={totalSelectedCash}
      />
      <Paginate collection={cashes} fetch={fetchCashes} />
      <EditModal
        cashOptions={cashOptions}
        cashes={cashes}
        closeModal={closeEditModal}
        isOpen={isEditModalOpen}
        selectedCashes={selectedCashes}
        updateCashesWithRefresh={updateCashesWithRefresh}
      />
    </Fragment>
  )
}

export default List
