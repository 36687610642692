import { useState } from "react"
import { Button, Card } from "components/UI"
import instance from "connection/instance"

const ActionsPanel = ({ cashes, selectedCashes, resetSelected }) => {
  const [disabled, setDisabled] = useState(false)

  const data = {
    query: {
      cashIds: selectedCashes.ids,
      type: selectedCashes.type,
      searchId: cashes.searchId
    }
  }
  const onSave = async () => {
    try {
      setDisabled(true)
      await instance.post("/payment_registries/create_by_cashes", { data })
      resetSelected()
      alert("Реестр выплат создан")
    } catch (error) {
      if (error.response.status === 422) {
        alert(error.response.data.errors.projectIds[0])
      }
    } finally {
      setDisabled(false)
    }
  }
  return (
    <Card>
      <Button onClick={onSave} variant="success" disabled={disabled}>
        Создать реестр выплат
      </Button>
    </Card>
  )
}

export default ActionsPanel
